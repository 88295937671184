/**
 * Resize listener that's build around requestAnimationFrame so it doesn't fire too often
 */
const optimizedResizeListener = () => {
    (function() {
        var throttle = function(type, name, obj) {
          obj = obj || window;
          var running = false;
          var func = function() {
            if (running) { return; }
            running = true;
            requestAnimationFrame(function() {
              obj.dispatchEvent(new CustomEvent(name));
              running = false;
            });
          };
          obj.addEventListener(type, func);
        };

        throttle("resize", "optimizedResize");
    })();
}

export { optimizedResizeListener }