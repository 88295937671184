import { optimizedResizeListener } from '../General/ResizeListener';
import { allowDraggableSliding } from '../Sliders/Sliders';

/**
 * Handle the resizing of the brandslider. On higher resolutions it should be a static version instead of a scrollable one
 */
const attachBrandSliderResizeListener = () => {
    optimizedResizeListener();
    checkWindowSize();

    window.addEventListener('optimizedResize', checkWindowSize);
}

const removeBrandSliderResizeListener = () => {
    window.removeEventListener('optimizedResize', checkWindowSize, false);
}

const checkWindowSize = () => {
    if (window.innerWidth < 1024) {
        allowDraggableSliding( true );
    } else {
        allowDraggableSliding( false );
    }
}

export { attachBrandSliderResizeListener, removeBrandSliderResizeListener }