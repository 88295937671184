const createMasonryGrid = ( data ) => {
    var container         = null

    if (data != null) {
        container = data.next.container
    } else {
        container = document
    }
    
    const caseCards         = container.querySelectorAll('.casestudy-card-col');
    const caseContainer     = container.querySelector('.casestudy-container');

    const numberOfColumns   = 2;
    var numberOfCards       = 1;
    
    var firstColumnHeight   = 0;
    var secondColumnHeight  = 0;

    caseCards.forEach( ( caseCard, _, __ ) => {
        if ( !( caseCard.classList.contains( "hidden" ) ) ) {
            var caseCardStyleProperties = window.getComputedStyle(caseCard);
            numberOfCards++;
            
            caseCard.style.order = ( ( numberOfCards + 1 ) % numberOfColumns === 0 ) ? numberOfColumns : ( numberOfCards + 1 ) % numberOfColumns;
            caseCard.style.paddingRight = ( ( numberOfCards + 1 ) % numberOfColumns === 0 ) ? '16px' : '40px';
            caseCard.style.paddingLeft = ( ( numberOfCards + 1 ) % numberOfColumns === 0 ) ? '40px' : '16px';

            if (!( (numberOfCards + 1) % numberOfColumns === 0 )) {
                firstColumnHeight += parseInt(caseCardStyleProperties.height) + parseInt(caseCardStyleProperties.marginBottom);
            } else {
                secondColumnHeight += parseInt(caseCardStyleProperties.height) + parseInt(caseCardStyleProperties.marginBottom)
            }
        }
    })

    caseContainer.style.height = ( firstColumnHeight > secondColumnHeight ) ? firstColumnHeight + "px" : secondColumnHeight + "px"
}

export { createMasonryGrid }
