import Swiper from 'swiper';

var draggableSlider;

/**
 * Draggable slider with momentum as used on the about page
 */
const createDraggableSlider = () => {
    draggableSlider = new Swiper('.sz-draggable-slider-container', {
        wrapperClass: 'sz-draggable-slider-wrapper',
        slideClass: 'sz-draggable-slide-wrapper',

        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
        grabCursor: true,
        slideToClickedSlide: true
    });
}

const allowDraggableSliding = ( isAllowed ) => {
    var slider = getBrandSlider()

    slider.el.style.cursor = isAllowed ? 'grab' : 'initial';

    slider.allowTouchMove = isAllowed
    slider.allowSlidePrev = isAllowed
    slider.allowSlideNext = isAllowed
}

const getBrandSlider = () => {
    var brandSlider;

    if (draggableSlider.length >= 1) {
        draggableSlider.forEach((slider) => {
            if (slider.wrapperEl.classList.contains('brand-slider')) {
                brandSlider = slider
            }
        })
    } else {
        brandSlider = draggableSlider
    }

    return brandSlider;
}


export { createDraggableSlider, allowDraggableSliding }